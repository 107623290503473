@import './mixins.scss';

// -------------------------------------
// classes

$nav-open-class:						'.nav-open';
$player-open-class:					'.player-open';

// -------------------------------------
// paths

$assets-path:								'../../assets';

// -------------------------------------
// colours

$color-light-peach:       	#dbd6d4;
$color-dusty-orange:      	#f3683d;
$color-burnt-sienna:      	#cd4217;
$color-ugly-blue:         	#2b7679;
$color-dark-green-blue:   	#1a585b;
$color-dark-blue-grey:    	#0f3335;
$color-twilight-blue:     	#0f447b;
$color-marine:            	#08325e;
$color-dark:              	#171d36;
$color-black:             	#1a130e;

// specific colours

$color-modal-bg:            #1b1715;

// -------------------------------------
// spacing

$spacer-base:             	0.5rem;
$spacer-1:                	$spacer-base; // 9px
$spacer-2:                	($spacer-base * 2); // 18px (1rem)
$spacer-3:                	($spacer-base * 3); // 27px
$spacer-4:                	($spacer-base * 4); // 36px
$spacer-5:                	($spacer-base * 5); // 45px
$spacer-6:                	($spacer-base * 6); // 54px
$spacer-7:                	($spacer-base * 7); // 63px
$spacer-8:                	($spacer-base * 8); // 72px
$spacer-9:                	($spacer-base * 9); // 81px
$spacer-10:                	($spacer-base * 10); // 90px

// -------------------------------------
// grid

$container-max-width:				calculateRem(1610px);
$container-padding-x-sm-up: $spacer-5;
$container-padding-x-xs:		$spacer-2;

// -------------------------------------
// typography

$font-family-bold:        	'Shentox Bold', 'Helvetica Neue', 'Helvetica', sans-serif;
$body-letter-spacing:       calculateRem(0.25px);
$paragraph-margin-bottom:   $spacer-3;

// -------------------------------------
// elements

// header

$scroll-progress-height:    calculateRem(5px);
$toggle-size:             	calculateRem(50px);
$header-padding-y-sm:       $spacer-5;
$header-height-scrolled:    calculateRem(50px);
$header-height:             calculateRem(100px);
$header-height-delay:       .1s;

// toolbar

$toolbar-btn-size:          calculateRem(50px);
$toolbar-btn-size-sm:       calculateRem(90px);
$toolbar-icon-size:         calculateRem(15px);
$toolbar-icon-size-sm:      calculateRem(22px);

// main

$main-p-top-xs:             $spacer-6;
$main-p-top-sm:             ($spacer-10 + $spacer-7);
$main-p-top-xl:             ($spacer-10 * 2);

// -------------------------------------
// transitions

$transition-base:         	.3s ease all;

// -------------------------------------
// bootstrap

// base

$body-bg:                 	$color-dark;
$body-color:              	$color-light-peach;
$font-family-base:        	'Shentox Regular', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-weight-bold:        	normal;
$line-height-base:        	1.618;
$enable-rounded:            false;

// grid

$grid-gutter-width:       	$spacer-4;

// links

$link-color:              	$color-dusty-orange;
$link-decoration:         	none;
$link-hover-color:        	lighten($color-dusty-orange, 5);
$link-hover-decoration:   	none;

// buttons

$btn-border-width:          2px;
$btn-font-size:             16px;

// grid

$grid-breakpoints: ();
$grid-breakpoints: map-merge(
	(
		xs: 0,
		sm: 576px,
		md: 769px,
		lg: 992px,
		xl: 1200px
	),
	$grid-breakpoints
);

// spacing

$spacer: $spacer-base;
$spacers: ();
$spacers: map-merge((
	0: 0,
	1: $spacer-base, // 18px
	2: ($spacer * 2), // 27px
	3: ($spacer * 3), // 36px
	4: ($spacer * 4), // 45px
	5: ($spacer * 5), // 54px
	6: ($spacer * 6), // 63px
	7: ($spacer * 7), // 72px
	8: ($spacer * 8), // 81px
	9: ($spacer * 9), // 90px
	10: ($spacer * 10), // 100px
	20: ($spacer * 20), // 200px
), $spacers);

// modals

$modal-backdrop-bg:           $color-black;
$modal-backdrop-opacity:      0.95;
$modal-lg:                    calculateRem(1080px);
$modal-content-bg:            $color-modal-bg;
$modal-header-padding-x:      $grid-gutter-width;
$modal-header-padding-y:      $spacer-5;
$modal-header-border-width:   0;
$modal-inner-padding:         $spacer-2 $grid-gutter-width $spacer-5 $grid-gutter-width;

// forms

$input-bg:                    transparent;
$input-border-color:          rgba($color-dark-green-blue, 0.5);
$input-height:                55px;
$input-padding-x:             $spacer-2;
$input-padding-y:             $spacer-1;
$input-focus-border-color:    rgba($color-dusty-orange, 0.4);
$input-focus-box-shadow:      0 0 0 0.2rem rgba($color-dusty-orange, 0.1);
$input-transition:            $transition-base;
$input-color:                 $body-color;
$label-margin-bottom:         $spacer-1;
$form-group-margin-bottom:    $spacer-3;