@import '../../assets/scss/_variables.scss';

// set loader colours
$primary-color: $color-dusty-orange;

@import '../../../node_modules/loaders.css/src/animations/pacman.scss';

.loading-screen {
	align-items: center;
	background: $color-black;
	bottom: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	left: 0;
	pointer-events: none;
	position: fixed;
	right: 0;
	top: 0;
	transition: $transition-base;
	visibility: visible;
	z-index: 2000;

	&::before, &::after {
		bottom: inherit;
		content: '';
		display: block;
		left: inherit;
		opacity: 0;
		position: inherit;
		right: inherit;
		top: inherit;
	}

	&::before {
		animation: fade-in 1s ease forwards;
		background: linear-gradient(45deg, rgba($color-dark, 0.5), rgba($color-dark-blue-grey, 0.5));
	}

	&::after {
		animation: fade-in 3s ease forwards;
		background: linear-gradient(-45deg, rgba($color-burnt-sienna, 0.1), rgba($color-dark-green-blue, 0.1));
	}

	&--hiding {
		opacity: 0;
		visibility: hidden;
	}

	&--hide {
		display: none;
	}

	.loader {
		animation: move 2.5s linear forwards;
	}

	&__name {
		animation: fade-in-name 2s ease forwards;
		color: $color-dusty-orange;
		left: 0;
		position: absolute;
		text-align: center;
		bottom: $spacer-5;
		width: 100%;
	}

}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-in-name {
	0% {
		opacity: 0;
		transform: translateY(20%);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes move {
	0% {
		opacity: 0;
		transform: translateX(-100%);
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: translateX(100%);
	}
}
