label {
	@include h5;
}

.error:empty {
	display: none;
}

.error {
	color: $color-dusty-orange;
	display: block;
	font-size: 80%;
	margin-top: $spacer-1;
}

.required-msg {
	color: $color-light-peach;
	display: block;
	font-size: 80%;
	margin: 0 0 $spacer-2 0;
	text-align: center;

	&:empty {
		display: none;
	}

}
