@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.intro {
  align-items: center;
  background: $color-dark;
  display: flex;
  height: 100vh;
  margin-top: -$main-p-top-xs;
  overflow: hidden;
  position: relative;
  text-align: center;
  z-index: 0;

  @include media-breakpoint-down(xs) {
    height: calc(100vh - 105px); // header height + scroll progress bar height
    padding: $spacer-10 $container-padding-x-xs;
  }

  @include media-breakpoint-up(sm) {
    margin-top: -$main-p-top-sm;
    min-height: calculateRem(600px);
  }

  @include media-breakpoint-up(xl) {
    margin-top: -$main-p-top-xl;
  }

  &__shapes {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__text {
    margin: 0 auto;
    padding: 0 $spacer-1;
    position: relative;
    width: 100%;

    @include media-breakpoint-only(sm) {
      padding: 0 $spacer-6;
    }

    h1 {
      color: $color-light-peach;
      font-family: $font-family-base;
      line-height: $line-height-base;
    }

    h1, p {
      @include fontSize(16px);

      @include media-breakpoint-up(sm) {
        @include fontSize(20px);
      }

      @include media-breakpoint-up(lg) {
        @include fontSize(24px);
      }
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 38rem;
  }

  &__footer {
    display: none;

    @include media-breakpoint-up(md) {
      bottom: $spacer-5;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      z-index: 1;
    }

    .container-fluid {
      max-width: none;
    }

  }

  &__lines {
    bottom: 0;
    left: 50%;
    pointer-events: none;
    position: absolute;
    width: 1px;

    span {
      animation-name: line;
      animation-duration: 5s;
      animation-iteration-count: infinite;
      background: $color-ugly-blue;
      display: block;
      height: calculateRem(25px);
      margin-top: calculateRem(25px);
      width: 1px;

      &:first-child {
        opacity: 0.05;
      }

      &:nth-child(2) {
        animation-delay: 0.8s;
        opacity: 0.2;
      }

      &:nth-child(3) {
        animation-delay: 1.6s;
        opacity: 0.4;
      }

      &:nth-child(4) {
        animation-delay: 2.4s;
        opacity: 0.6;
      }

      &:nth-child(5) {
        animation-delay: 3.2s;
        opacity: 0.8;
      }

      &:nth-child(6) {
        animation-delay: 4s;
        opacity: 1;
      }

    }

  }

}

@keyframes line {
  0% {
    background: $color-ugly-blue;
  }
  10% {
    background: $color-dusty-orange;
  }
}
