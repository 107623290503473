.apple-music-embed__content {
  margin-bottom: 2.5rem;

  iframe {
    background: transparent;
    border-radius: 1rem;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
  }
}
