@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.project-card {
  display: block;
  margin-bottom: $grid-gutter-width;
  position: relative;
  text-align: center;

  &:hover {
    img, video {
      opacity: 1;
    }
    h3 {
      color: $link-hover-color;
    }

    @include media-breakpoint-up(md) {
      .project-card__text {
        opacity: 1;

        h3, p {
          opacity: 1;
          transform: translateY(0);
        }

      }
    }
  }

  &:focus {
    box-shadow: none;
    outline: none;

    picture, video {
      @include focus;
    }
  }

  figure {
    margin-bottom: 0;
    overflow: hidden;
  }

  img, video {
    transition: .5s ease all;

    @include media-breakpoint-up(md) {
      opacity: 0.7;
    }
  }

  &__video {
    @include aspect-ratio(1,1);

    video {
      min-width: 1000%;
      min-height: 1000%;
      left: 50%;
      pointer-events: none;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) scale(0.11);
    }

  }

  &__text {

    @include media-breakpoint-down(sm) {
      margin-top: $spacer-2;
    }

    @include media-breakpoint-up(md) {
      background: linear-gradient(to bottom, rgba($color-dark-green-blue, 0.6) 0%,rgba($color-black, 0.9) 100%); ;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      left: 0;
      opacity: 0;
      padding-bottom: $spacer-4;
      position: absolute;
      right: 0;
      top: 0;
      transition: $transition-base;
      width: 100%;

      h3, p {
        opacity: 0;
        transition: $transition-base;
        transform: translateY(20%);
      }

      h3 {
        transition-delay: .2s;
      }

      p {
        transition-delay: .3s;
      }

    }

    h3 {
      @include fontSize(20px);
      color: $color-dusty-orange;
      margin-bottom: ($spacer-1 / 2);

      @include media-breakpoint-up(md) {
        @include fontSize(24px);
      }
    }

    .tag {
      color: $color-light-peach;
    }

  }

}

.hide-card {
  display: none !important;
}

.show-card {
  display: block !important;
}
