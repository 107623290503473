@import '../../assets/scss/variables';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.toolbar {
	position: fixed;
	right: 0;
	top: 50%;
	transform: translate3d(50%,-50%,0);

	ul {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		font-size: 0;
	}

	&__btn {
		align-items: center;
		appearance: none;
		background: linear-gradient(to right, $color-dark-blue-grey, $color-black);
		border: 0;
		border-radius: $toolbar-btn-size;
		box-shadow: 0 0 $spacer-2 rgba($color-black, 0.1);
		display: flex;
		flex-direction: row;
		height: $toolbar-btn-size;
		justify-content: flex-start;
		margin: $spacer-1 0;
		padding: 0;
		transition: $transition-base;
		width: $toolbar-btn-size;

		@include media-breakpoint-up(sm) {
			border-radius: $toolbar-btn-size-sm;
			height: $toolbar-btn-size-sm;
			width: $toolbar-btn-size-sm;
		}

		@include media-breakpoint-up(md) {

			&:hover {
				transform: translateX(-5%);

				&::before {
					opacity: 1;
				}

				&::after {
					opacity: 0.2;
				}

				span {
					left: -$spacer-1;
					opacity: 1;
					visibility: visible;
				}

			}

		}

		&::before {
			content: '';
			display: block;
			height: $toolbar-icon-size;
			margin-left: ($spacer-1 / 1.5);
			opacity: 0.8;
			transition: inherit;
			width: $toolbar-icon-size;

			@include media-breakpoint-up(sm) {
				height: $toolbar-icon-size-sm;
				margin-left: ($spacer-1 * 1.5);
				width: $toolbar-icon-size-sm;
			}

		}

		&::after {
			background: $color-black;
			border-radius: 50%;
			bottom: 0;
			content: '';
			display: block;
			left: 0;
			opacity: 0;
			pointer-events: none;
			position: absolute;
			right: 0;
			top: 0;
			transition: inherit;
		}

		&--player::before {
			background: url($assets-path + '/icons/icon-headphones-external.svg') center center / 100% no-repeat;

			@include media-breakpoint-up(sm) {
				background: url($assets-path + '/icons/icon-headphones.svg') center center / 100% no-repeat;
			}
		}

		&--contact::before {
			background: url($assets-path + '/icons/icon-envelope.svg') center center / 100% no-repeat;
		}

		span {
			@include fontSize(14px);
			color: $color-dusty-orange;
			display: none;
			left: 0;
			letter-spacing: calculateRem(1.5px);
			opacity: 0;
			position: absolute;
			top: 50%;
			text-transform: uppercase;
			transform: translate3d(-100%, -50%, 0);
			transition: $transition-base;
			visibility: hidden;

			@include media-breakpoint-up(md) {
				display: block;
			}

		}

	}

}
