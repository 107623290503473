@import '../../assets/scss/variables';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.back-home {
	text-align: center;

	@include media-breakpoint-up(lg) {
		left: $container-padding-x-sm-up;
		position: fixed;
		text-align: left;
		top: 50%;
		transform: translateY(-50%);

		a {
			opacity: 0.7;
		}

		a:hover {
			opacity: 1;
		}

	}

	a {
		@include fontSize(16px);
		text-transform: uppercase;
	}

	a::before {
		background: url($assets-path + '/icons/icon-left-arrow.svg') center center / 70% no-repeat;
		content: '';
		display: inline-block;
		height: calculateRem(20px);
		margin-right: calculateRem(4px);
		opacity: 0.8;
		position: relative;
		top: -1px;
		transition: $transition-base;
		vertical-align: middle;
		width: calculateRem(20px);
	}

	a:hover::before {
		opacity: 1;
	}

}
