@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.project-track .project-track__embed {
	@include media-breakpoint-down(xs) {
		margin-left: -($grid-gutter-width / 2);
		margin-right: -($grid-gutter-width / 2);
	}
}
