@import '../../assets/scss/_variables.scss';
@import '../../assets/scss/_mixins.scss';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/vendor/_rfs.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_border-radius.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_buttons.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_box-shadow.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_transition.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_hover.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_gradients.scss';
@import '../../../node_modules/bootstrap-scss/_buttons.scss';

button, .btn {
  appearance: none;
  border: 0;
  cursor: pointer;
  transition: $transition-base;

  &:focus {
    @include focus;
  }
}

.btn--fancy {
  padding: $spacer-3;
  position: relative;
  text-align: center;

  &::before, &::after, .btn-outer::before, .btn-outer::after {
    background: rgba($color-dusty-orange, 0.25);
    content: '';
    display: block;
    position: absolute;
    transition: $transition-base;
  }

  &::before, &::after {
    height: calculateRem(10px);
    left: 50%;
    width: 1px;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  .btn-outer::before, .btn-outer::after {
    height: 1px;
    top: 50%;
    width: calculateRem(10px);
  }

  .btn-outer::before {
    left: 0;
  }

  .btn-outer::after {
    right: 0;
  }

  .btn-outer, .btn-text {
    background: $color-dusty-orange;
    display: block;
    line-height: calculateRem(46px);
    transition: all .5s ease;
  }

  .btn-outer {
    @include h5;
    border: 2px solid rgba($color-black, 0.25);
    height: calculateRem(50px);
    margin-bottom: 0;
    min-width: calculateRem(200px);
    overflow: hidden;
  }

  .btn-text {
    color: $color-twilight-blue;
    padding: 0 $spacer-3;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  // hover

  &:hover {

    &::before, &::after, .btn-outer::before, .btn-outer::after {
      background: rgba($color-twilight-blue, 0.5);
    }

    &::before, &::after {
      height: $spacer-2;
    }

    &::before {
      top: $spacer-1;
    }

    &::after {
      bottom: $spacer-1;
    }

    .btn-outer {
      &::before, &::after {
        width: $spacer-2;
      }

      &::before {
        left: $spacer-1;
      }

      &::after {
        right: $spacer-1;
      }
    }

    .btn-outer, .btn-text {
      background: $color-twilight-blue;
    }

    .btn-text {
      @include fontSize(16.5px);
      color: $color-dusty-orange;
    }

  }

  // focus

  &:focus {
    border-color: transparent;
    box-shadow: none;

    //&::before, &::after, .btn-outer::before, .btn-outer::after {
    //  background: rgba($color-twilight-blue, 0.5);
    //}
    //
    //.btn-outer, .btn-text {
    //  background: $color-twilight-blue;
    //}
    //
    //.btn-text {
    //  color: $color-black;
    //}

  }

  // active style

  &.btn--active {

    .btn-outer, .btn-text {
      background: $color-dusty-orange;
    }

    .btn-text {
      animation: spin 1s 0s linear infinite;
      border-color: rgba($color-black, 0.1);
      border-left-color: rgba($color-marine, 0.6);
      border-radius: 50%;
      border-style: solid;
      border-width: 4px;
      height: calculateRem(30px);
      margin: $spacer-1 auto;
      overflow: hidden;
      padding: 0;
      text-indent: -999px;
      transition: none;
      width: calculateRem(30px);
    }

  }

}

button:disabled {
  opacity: 0.8;
  pointer-events: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
