@import '../../assets/scss/variables';
@import '../../assets/scss/mixins.scss';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.page-text {
	margin-bottom: $spacer-3;
	text-align: center;

	@include media-breakpoint-up(md) {
		margin-bottom: $spacer-5;
	}

	&:last-child {
		margin-bottom: $spacer-5;

		@include media-breakpoint-up(lg) {
			margin-bottom: $spacer-10 + $spacer-5;
		}
	}

	&__wrap {
		margin: 0 auto;
		max-width: 50rem;

		@include media-breakpoint-up(xl) {
			padding-left: $spacer-5;
			padding-right: $spacer-5;
		}

		p:last-child {
			margin-bottom: 0;
		}

	}

}
