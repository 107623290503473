/**
 * Convert px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 *
 */

// function for converting a px based font-size to rem
@function calculateRem($size) {
  $remSize: $size / 18px;
  // default font size on html element is 100%, equivalent to 16px
  @return #{$remSize}rem;
}

// mixin that will include the fall back px declaration as well as the calculated rem value
@mixin fontSize($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// Placeholder @mixin for Sass
//
// A mixin to style placeholders in HTML5 form elements.
// Includes also a .placeholder class to be used with a polyfill e.g.
// https://github.com/mathiasbynens/jquery-placeholder
// Requires Sass 3.2.
//
// Example usage (.scss):
//
// input {
//   @include placeholder {
//     /* styles for placeholder here */
//   }
// }
//

@mixin placeholder {
  &.placeholder { @content }
  &:-moz-placeholder { @content }
  &::-webkit-input-placeholder { @content }
}

// --------------------------------------------------
// animations
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

// focus transition
@mixin focus() {
  border-color: rgba($color-dusty-orange, 0.25);
  box-shadow: 0 0 0 calculateRem(3px) rgba($color-dusty-orange, 0.35);
  outline: none;
}

@mixin focus-minimal() {
  outline: none;
}

// --------------------------------------------------
// aspect ratio
// e.g. @include aspect-ratio(16,9);
// expects child to have class of `.content` (could be changed to a wildcard `*`)

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// --------------------------------------------------
// contexts
@mixin when-inside($selector) {
  #{$selector} & {
    @content;
  }
}

// transition for components
@mixin component-transition() {
  transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

// icon hover style
@mixin icon-hover-style {
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

// typography
@mixin h1 {
  @include fontSize(30px);
  color: $color-ugly-blue;
  font-family: $font-family-bold;
  letter-spacing: $body-letter-spacing;
  line-height: 1.25;
  margin: 0 0 $spacer-3 0;
  text-align: center;
  text-transform: none;

  @include media-breakpoint-only(sm) {
    @include fontSize(40px);
  }

  @include media-breakpoint-only(md) {
    @include fontSize(50px);
  }

  @include media-breakpoint-up(lg) {
    @include fontSize(62px);
  }
}

@mixin h3 {
  @include fontSize(30px);
  color: $color-light-peach;
  font-family: $font-family-bold;
  letter-spacing: $body-letter-spacing;
  line-height: 1.25;
  margin: 0 0 $spacer-2 0;
  text-transform: none;

  @include media-breakpoint-only(md) {
    @include fontSize(35px);
  }

  @include media-breakpoint-up(lg) {
    @include fontSize(40px);
  }

}

@mixin h4 {
  @include fontSize(16px);
  color: $color-ugly-blue;
  font-family: $font-family-bold;
  letter-spacing: calculateRem(2px);
  line-height: 1.25;
  margin: 0 0 $spacer-2 0;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    @include fontSize(20px);
  }

}

@mixin h5 {
  @include fontSize(14px);
  color: $color-ugly-blue;
  font-family: $font-family-base;
  letter-spacing: calculateRem(1px);
  line-height: 1.5625;
  margin: 0 0 $spacer-1 0;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    @include fontSize(16px);
  }
}
