@font-face {
  font-family: "Shentox Regular";
  src: url("/assets/fonts/ShentoxReg/ShentoxReg.eot?#iefix");
  src: url("/assets/fonts/ShentoxReg/ShentoxReg.eot?#iefix") format("eot"),
  url("/assets/fonts/ShentoxReg/ShentoxReg.woff2") format("woff2"),
  url("/assets/fonts/ShentoxReg/ShentoxReg.woff") format("woff"),
  url("/assets/fonts/ShentoxReg/ShentoxReg.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Shentox Bold";
  src: url("/assets/fonts/ShentoxBold/ShentoxBold.eot?#iefix");
  src: url("/assets/fonts/ShentoxBold/ShentoxBold.eot?#iefix") format("eot"),
  url("/assets/fonts/ShentoxBold/ShentoxBold.woff2") format("woff2"),
  url("/assets/fonts/ShentoxBold/ShentoxBold.woff") format("woff"),
  url("/assets/fonts/ShentoxBold/ShentoxBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

h1, .h1, h2, .h2 {
  @include h1;
}

h3, .h3 {
  @include h3;
}

h4, .h4 {
  @include h4;
}

h5, .h5 {
  @include h5;
}

.lead {

  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-only(sm) {
    @include fontSize(18px);
  }

  @include media-breakpoint-up(md) {
    @include fontSize(24px);
  }
}

a {
  transition: $transition-base;

  &:focus {
    @include focus-minimal;
  }

  //&[target="_blank"]::after {
  //  background: url('./assets/icons/icon-external.svg') center center / 100% no-repeat;
  //  content: '';
  //  display: inline-block;
  //  height: calculateRem(10px);
  //  margin: 0 2px;
  //  opacity: 0.7;
  //  position: relative;
  //  top: -3px;
  //  transition: $transition-base;
  //  width: calculateRem(10px);
  //}

}

::selection {
  background: rgba($color-dusty-orange, 0.25);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.capitalised {
  @include fontSize(16px);
  letter-spacing: calculateRem(0.22px);
  text-transform: uppercase;
}

.tag {
  color: $color-ugly-blue;
}

hr {
  background: rgba($color-dusty-orange, 0.5);
  border: 0;
  border-radius: 2px;
  height: 2px;
  margin: $spacer-3 0;
  padding: 0;

  @include media-breakpoint-up(lg) {
    margin-bottom: $spacer-5;
    margin-top: $spacer-5;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: $spacer-8;
    margin-top: $spacer-8;
  }

}

strong {
  font-family: $font-family-bold;
  font-weight: normal;
}

.break-xs {
  @include media-breakpoint-down(xs) {
    display: block;
  }
}
