@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.scroll-progress-bar {
  background-color: $color-marine;
  height: $scroll-progress-height;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: $transition-base;
  z-index: 1001;

  @include when-inside($nav-open-class) {
    @include media-breakpoint-up(sm) {
      opacity: 0;
    }
  }

  &__inner {
    background-color: rgba($color-dusty-orange, 0.5);
    display: block;
    height: 100%;
  }
}
