@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/buttons.scss';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.header {
	@include component-transition;
	left: 0;
	position: fixed;
	right: 0;
	top: calculateRem(5px);
	transition: transform 500ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
	z-index: 1000;

	@include media-breakpoint-down(xs) {
		box-shadow: 0 0 calculateRem(10px) rgba(0, 0, 0, 0.1);

		& > .container-fluid {
			padding-left: 0;
			padding-right: 0;

			& > .row {
				margin-left: 0;
				margin-right: 0;

				> .col,
				> [class*="col-"] {
					padding-right: 0;
					padding-left: 0;
				}
			}
		}

	}

	@include media-breakpoint-up(sm) {
		padding: $header-padding-y-sm 0;
		position: absolute;
	}

	.container-fluid {
		max-width: none;
	}

	&--scrolled {

		@include media-breakpoint-down(xs) {

			.header__title {
				height: $header-height-scrolled;
			}

			.header__title > span:last-child {
				opacity: 0;
				visibility: hidden;
			}

		}

		@include media-breakpoint-up(sm) {

			.header__title {
				margin-top: -$header-padding-y-sm;
				transform: translateY(-100%);
			}

			.header__toggle .toggle-title {
				opacity: 0;
				visibility: hidden;
			}

		}

	}

	&__title {
		@include fontSize(20px);
		font-family: $font-family-bold;
		text-transform: lowercase;

		@include media-breakpoint-down(xs) {
			background-color: $color-dark;
			display: block;
			height: $header-height;
			line-height: $toggle-size;
			overflow: hidden;
			transition: $transition-base;
			transition-delay: $header-height-delay;
			width: 100%;
		}

		@include media-breakpoint-only(sm) {
			line-height: 1.25;
		}

		@include media-breakpoint-up(xl) {
			@include fontSize(26px);
		}

		& > span {
			@include media-breakpoint-down(xs) {
				padding: 0 $container-padding-x-xs;
			}
		}

		& > span:first-child span::before {
			content: '_';
			display: inline;
			white-space: nowrap;
		}

		& > span:last-child {
			font-family: $font-family-base;

			@include media-breakpoint-down(xs) {
				background-color: $color-marine;
				height: $toggle-size;
				transition: $transition-base;
			}

			@include media-breakpoint-down(sm) {
				display: block;
			}

			@include media-breakpoint-up(md) {
				margin-left: calculateRem(5px);
			}
		}
	}

	&__toggle {
		align-items: center;
		background: transparent;
		color: $color-ugly-blue;
		display: flex;
		flex-direction: row;
		padding: 0;

		@include media-breakpoint-down(xs) {
			position: absolute;
			right: 0;
			top: 0;
		}

		@include media-breakpoint-up(sm) {
			position: fixed;
			right: $container-padding-x-sm-up;
			top: inherit; // $header-padding-y-sm + $scroll-progress-height
		}

		&:focus {
			border: 0;
			box-shadow: none;
		}

		&:hover {
			.toggle-title {
				color: $color-dusty-orange;
			}

			.toggle-box {
				background-color: rgba($color-dusty-orange, 0.5);

				.toggle-line {
					background-color: $color-dark;
				}
			}
		}

		.toggle-title {
			margin: 0 ($spacer-1 * 1.5);
			transition: inherit;

			@include media-breakpoint-down(xs) {
				display: none;
			}
		}

		.toggle-box {
			background-color: $color-black;
			border: calculateRem(2px) solid rgba($color-dusty-orange, 0.25);
			display: flex;
			flex-direction: column;
			height: $toggle-size;
			justify-content: space-between;
			overflow: hidden;
			padding: $spacer-1 ($spacer-1 / 2);
			position: relative;
			transition: inherit;
			width: $toggle-size;

			&::before {
				background-color: $color-ugly-blue;
				bottom: 0;
				content: '';
				display: block;
				height: 200%;
				position: absolute;
				right: 20%;
				transform: rotate(45deg);
				transition: inherit;
				width: 100%;
			}
		}

		.toggle-line {
			background-color: $color-dusty-orange;
			border-radius: calculateRem(2px);
			display: block;
			height: calculateRem(2px);
			position: relative;
			transition: inherit;
		}

		.toggle-line:first-child, .toggle-line:last-child {
			width: 50%;
		}

		.toggle-line:nth-child(2) {
			width: 100%;
		}

		.toggle-line:last-child {
			align-self: flex-end;
		}

		@include when-inside($nav-open-class) {
			.toggle-line:first-child {
				transform: rotate(45deg) translate3d(20%, -200%, 0);
				transform-origin: left top;
				width: 51%;
			}

			.toggle-line:nth-child(2) {
				transform: rotate(-45deg);
			}

			.toggle-line:last-child {
				transform: rotate(45deg) translate3d(-20%, 200%, 0);
				transform-origin: right bottom;
				width: 51%;
			}
		}
	}
}
