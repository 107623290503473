@import '../../assets/scss/variables';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.project-footer {
	margin-bottom: $spacer-5;
	margin-top: $spacer-6;

	@include media-breakpoint-up(sm) {
		margin-top: $spacer-10;
	}

	@include media-breakpoint-up(md) {
		margin-bottom: $spacer-10;
	}

	&__title {
		margin-bottom: 0;
		padding: $spacer-3 0;
		position: relative;
		text-align: center;

		&::before {
			background: $color-marine;
			border-radius: 2px;
			content: '';
			display: block;
			height: 2px;
			left: 0;
			opacity: 0.5;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}

	&__nav {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		a {
			flex-basis: calc(50% - #{$grid-gutter-width / 2});
			font-family: $font-family-bold;
			line-height: 1.25;
			position: relative;
			width: calc(50% - #{$grid-gutter-width / 2});

			@include media-breakpoint-up(lg) {
				@include fontSize(40px);
			}

			&::before, &::after {
				border-radius: 50%;
				content: '';
				display: block;
				height: calculateRem(50px);
				width: calculateRem(50px);

				@include media-breakpoint-up(lg) {
					height: calculateRem(80px);
					width: calculateRem(80px);
				}
			}

			&::before {
				border: 2px solid transparent;
				margin-bottom: $spacer-1;

				@include media-breakpoint-up(sm) {
					position: absolute;
					top: 0;
				}
			}

			&::after {
				border: 2px solid rgba($color-dusty-orange, 0.5);
				left: 0;
				position: absolute;
				top: 0;
			}

			@include media-breakpoint-up(sm) {
				padding-top: $spacer-1;
			}

			.tag {
				@include media-breakpoint-down(xs) {
					@include fontSize(13px);
				}

				@include media-breakpoint-up(sm) {
					@include fontSize(16px);
				}

				@include media-breakpoint-up(lg) {
					@include fontSize(18px);
				}
			}
		}

		a > span {
			display: block;
			font-family: $font-family-base;
			font-size: 0;
		}

		a:first-child {
			margin-right: ($grid-gutter-width / 2);

			&:hover::before {
				animation: nudge-left 1.5s 1;
			}

			@include media-breakpoint-up(sm) {
				padding-left: calculateRem(80px);

				&::before {
					left: 0;
				}
			}

			@include media-breakpoint-up(lg) {
				padding-left: calculateRem(110px);
			}

			&::before {
				background: url($assets-path + '/icons/icon-left-arrow.svg') no-repeat center center / 50%;
			}
		}

		a:last-child {
			margin-left: ($grid-gutter-width / 2);
			text-align: right;

			&:hover::before {
				animation: nudge-right 1.5s 1;
			}

			@include media-breakpoint-up(sm) {
				padding-right: calculateRem(80px);

				&::before, &::after {
					left: auto;
					right: 0;
				}
			}

			@include media-breakpoint-up(lg) {
				padding-right: calculateRem(110px);
			}

			&::before {
				background: url($assets-path + '/icons/icon-right-arrow.svg') no-repeat center center / 50%;
				margin-left: auto;
			}

			&::after {
				left: auto;
				right: 0;
			}
		}
	}

}

@keyframes nudge-left {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(-5%);
	}
	50% {
		transform: translateX(0);
	}
	75% {
		transform: translateX(-2.5%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes nudge-right {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(5%);
	}
	50% {
		transform: translateX(0);
	}
	75% {
		transform: translateX(2.5%);
	}
	100% {
		transform: translateX(0);
	}
}
