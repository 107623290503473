@import '../../assets/scss/_variables.scss';
@import "~slick-carousel/slick/slick.css";
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.player {
	@include component-transition;
	background-color: rgba($color-black, 0.95);
	border-top: 2px solid rgba($color-ugly-blue, 0.1);
	bottom: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	left: 0;
	padding: ($spacer-3 / 2) 25%;
	position: fixed;
	right: 0;
	transform: translateY(100%);
	z-index: 998;

	@include media-breakpoint-up(sm) {
		padding-left: $container-padding-x-sm-up;
		padding-right: $container-padding-x-sm-up;
	}

	&__inner {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		max-width: $container-max-width;
		width: 100%;
	}

	&__slider {
		margin: 0 $spacer-10;
		max-width: calculateRem(400px);
		position: relative;
		width: 100%;

		div, iframe {
			font-size: 0;
		}

		.slick-arrow {
			border-radius: 50%;
			height: calculateRem(16px);
			opacity: 0.8;
			position: absolute;
			top: 2px;
			transition: $transition-base;
			width: calculateRem(16px);

			&:hover {
				opacity: 1;
			}
		}

		.slick-prev {
			background: url($assets-path + '/icons/icon-player-prev.svg') no-repeat center center / contain;
			left: -($spacer-10);
			transform: translateX(100%);
		}

		.slick-next {
			background: url($assets-path + '/icons/icon-player-next.svg') no-repeat center center / contain;
			right: -($spacer-10);
			transform: translateX(-100%);
		}

		.slick-initialized .slick-slide.slick-active {
			z-index: 1;
		}
	}

	&__hide {
		background: 0 0;
		border: 1px solid transparent;
		color: $color-ugly-blue;
		display: none;
		opacity: 0.8;
		padding: 0;
		position: absolute;
		right: $container-padding-x-sm-up;
		top: 50%;
		transform: translateY(-50%);
		transition: $transition-base;

		@include media-breakpoint-up(sm) {
			display: block;
		}

		&:hover {
			opacity: 1;
		}

		&::after {
			background: url($assets-path + '/icons/icon-down.svg') no-repeat center center / contain;
			content: '';
			display: inline-block;
			height: calculateRem(16px);
			margin-left: $spacer-1;
			position: relative;
			top: -2px;
			vertical-align: middle;
			width: calculateRem(16px);
		}
	}
}

body.player-open .player {
	transform: translateY(0);
}
