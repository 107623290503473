@import '../../assets/scss/_variables.scss';
@import '../../assets/scss/_mixins.scss';
@import '../../../node_modules/bootstrap-scss/mixins.scss';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/vendor/_rfs.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_border-radius.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_box-shadow.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_transition.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_gradients.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_forms.scss';
@import '../../../node_modules/bootstrap-scss/_transitions.scss';
@import '../../../node_modules/bootstrap-scss/_modal.scss';
@import '../../../node_modules/bootstrap-scss/_forms.scss';

.modal {
	@include media-breakpoint-up(md) {
		border: calculateRem(10px) solid rgba($color-dark, 0.5);
	}
}

.modal-content {
	margin: $spacer-10 0;
	text-align: center;

	@include media-breakpoint-up(sm) {
		margin-left: $grid-gutter-width;
		margin-right: $grid-gutter-width;
	}

}

.modal-header, .modal-body {

	@include media-breakpoint-up(md) {
		padding-left: 10%;
		padding-right: 10%;
	}

	@include media-breakpoint-up(lg) {
		padding-left: $spacer-10;
		padding-right: $spacer-10;
	}

	@include media-breakpoint-up(xl) {
		padding-left: ($spacer-10 + $spacer-5);
		padding-right: ($spacer-10 + $spacer-5);
	}

}

.modal-header {
	padding-bottom: 0;

	@include media-breakpoint-up(md) {
		padding-top: $spacer-10;
	}
}

.modal-header .close {
	@include icon-hover-style;
	background: url($assets-path + '/icons/icon-close.svg') center center / 80% no-repeat ;
	border: 0;
	font-size: 0;
	height: calculateRem(46px);
	margin: 0;
	padding: 0;
	position: absolute;
	right: 0;
	top: -($spacer-3);
	transform: translateY(-100%);
	width: calculateRem(46px);
}

.modal-title {
	@include h3;
	margin-bottom: 0;
	width: 100%;
}

.modal-body {

	@include media-breakpoint-up(md) {
		padding-bottom: $spacer-10;
	}

	form {
		margin-top: $spacer-5;
		text-align: left;
	}

	.btn {
		display: block;
		margin: 0 auto;
	}

}
