@import '../../assets/scss/variables';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.project-content {
	margin-bottom: $spacer-3;

	@include media-breakpoint-up(md) {
		margin-bottom: $spacer-5;
	}

	&:last-child {
		margin-bottom: $spacer-8;

		@include media-breakpoint-up(md) {
			margin-bottom: $spacer-10;
		}

	}

}