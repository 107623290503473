@import '../../assets/scss/variables';

.page-header {
	padding-bottom: $spacer-5;
	position: relative;
	text-align: center;

	h1 {
		margin-bottom: $spacer-2;
	}

	h1:last-child {
		margin-bottom: 0;
	}

}
