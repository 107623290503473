@import '../../assets/scss/_variables.scss';
@import "~slick-carousel/slick/slick.css";
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.testimonials {
	background-color: $color-dark-blue-grey;
	padding: $spacer-8 0 0;

	@include media-breakpoint-up(sm) {
		padding-bottom: $spacer-8;
	}

	h2 {
		color: $color-light-peach;
	}

	&__slide {
		background-color: rgba($color-black, 0.1);
		display: flex !important;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		padding: $spacer-3 $spacer-8;
		text-align: center;

		@include media-breakpoint-up(sm) {
			padding: $spacer-5;
		}

		blockquote {
			@include fontSize(16px);
			margin: 0 0 $spacer-2 0;
		}

		cite {
			@include fontSize(16px);
			color: $color-dusty-orange;
			font-family: $font-family-bold;
			font-style: normal;
			font-weight: normal;
			line-height: 1.25;

			span {
				display: block;
				font-family: $font-family-base;
			}

			&::after {
				background-color: $color-dusty-orange;
				content: '';
				display: block;
				height: calculateRem(5px);
				margin: $spacer-1 auto 0 auto;
				opacity: 0.25;
				width: calculateRem(42px);
			}
		}

	}

	.slick-slider {
		margin-top: $spacer-5;
	}

	.slick-slide {
		height: auto;

		@include media-breakpoint-up(xl) {
			padding: 0 ($grid-gutter-width / 2);
		}

		& > div, .testimonials__slide {
			height: 100%;
		}

	}

	.slick-list {
		@include media-breakpoint-up(xl) {
			margin: 0 ($grid-gutter-width / 2);
		}
	}

	.slick-track {
		align-items: stretch;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.slick-slide {
		flex: 1;
	}

	.slick-arrow {
		@include icon-hover-style;
		background-color: transparent;
		height: calculateRem(50px);
		overflow: hidden;
		padding: 0;
		position: absolute;
		text-indent: -999px;
		top: 50%;
		width: calculateRem(23px);
		z-index: 1;

		&:focus {
			border-color: transparent;
			box-shadow: none;
		}

		&.slick-prev {
			background: url($assets-path + '/icons/icon-testimonials-prev.svg') no-repeat center center / contain;
			left: ($grid-gutter-width / 2);
			transform: translate3d(0%,-50%,0);

			@include media-breakpoint-up(sm) {
				left: -($grid-gutter-width / 2);
				transform: translate3d(-100%, -50%, 0);
			}
		}

		&.slick-next {
			background: url($assets-path + '/icons/icon-testimonials-next.svg') no-repeat center center / contain;
			right: ($grid-gutter-width / 2);
			transform: translate3d(0%,-50%,0);

			@include media-breakpoint-up(sm) {
				right: -($grid-gutter-width / 2);
				transform: translate3d(100%, -50%, 0);
			}
		}
	}

	.slick-dots {
		font-size: 0;
		list-style: none;
		margin: $spacer-4 0 0 0;
		padding: 0;
		text-align: center;

		li {
			display: inline-block;
			font-style: inherit;
			padding: 6px;
		}

		button {
			@include icon-hover-style;
			background-color: $color-dusty-orange;
			border-radius: 50%;
			height: 14px;
			padding: 0;
			position: relative;
			width: 14px;
		}

		.slick-active button {
			background-color: $color-ugly-blue;
			opacity: 1;

			&::before {
				border: 1px solid rgba($color-dusty-orange, 0.5);
				border-radius: 50%;
				content: '';
				display: block;
				height: 22px;
				left: 0;
				position: absolute;
				top: 0;
				transform: translate3d(-4px,-4px,0);
				width: 22px;
			}
		}
	}

}
