@import './assets/scss/variables.scss';
@import '../node_modules/bootstrap-scss/bootstrap-reboot';
@import '../node_modules/bootstrap-scss/bootstrap-grid';
@import '../node_modules/bootstrap-scss/utilities/embed';
@import './assets/scss/mixins.scss';
@import './assets/scss/typography.scss';
@import './assets/scss/buttons.scss';
@import './assets/scss/forms.scss';

html {
  background-color: $body-bg;
  font-size: 18px;
}

body {
  letter-spacing: $body-letter-spacing;
  padding-top: calculateRem(5px);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.nav-open {
  height: 100%;
  overflow: hidden;
}

body::before {
  background: linear-gradient($color-dark-blue-grey, rgba(0,0,0,0));
  content: '';
  display: block;
  height: calculateRem(500px);
  left: 0;
  position: fixed;
  right: 0;
  top: calculateRem(5px);
  z-index: 0;
}

.main {
  margin-top: $header-height;
  padding-top: $main-p-top-xs;
  position: relative;

  @include media-breakpoint-down(xs) {
    transition: $transition-base;
    transition-delay: $header-height-delay;
  }

  @include media-breakpoint-up(sm) {
    margin-top: 0;
    padding-top: $main-p-top-sm;
  }

  @include media-breakpoint-up(xl) {
    padding-top: $main-p-top-xl;
  }

}

.header--scrolled ~ .transition-group .main {
  @include media-breakpoint-down(xs) {
    margin-top: $header-height-scrolled;
  }
}

.container-fluid {
  max-width: $container-max-width;
  padding-left: $container-padding-x-xs;
  padding-right: $container-padding-x-xs;

  @include media-breakpoint-up(sm) {
    padding-left: $container-padding-x-sm-up;
    padding-right: $container-padding-x-sm-up;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: $transition-base;
  transform: translateY(0);
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: $transition-base;
}

.transition-group {
  position: relative;
}

.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
