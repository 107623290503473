@import '../../assets/scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.work {
  padding: $spacer-8 0;

  &__cats {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    margin: 0 0 $spacer-5 0;
    padding: 0;

    @include media-breakpoint-down(xs) {
      //overflow-y: auto;
      //white-space: nowrap;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: $spacer-6;
    }

    li {
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    li:not(:last-child)::after {
      background-color: rgba($color-dusty-orange, 0.5);
      content: '';
      display: inline-block;
      height: 1px;
      margin: 0 $spacer-1;
      width: 1rem;

      @include media-breakpoint-up(sm) {
        width: calculateRem(25px);
      }
    }

    a {
      letter-spacing: calculateRem(1px);
      text-transform: uppercase;

      @include media-breakpoint-down(xs) {
        @include fontSize(14px);
      }

      &.active {
        color: $color-light-peach;
      }
    }
  }

  &__projects {

    div[class^="col"]:nth-child(n+13) {
      display: none;
    }

  }

}
