@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.social {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  margin-left: -(($spacer-1 * 1.5) / 2);
  margin-right: -(($spacer-1 * 1.5) / 2);
  margin-top: 0;
  padding: 0;

  li {
    margin: 0 (($spacer-1 * 1.5) / 2);
  }

  &__icon {
    @include icon-hover-style;
    border-radius: 50%;
    display: block;
    height: calculateRem(30px);
    overflow: hidden;
    text-indent: -999px;
    width: calculateRem(30px);

    &--apple-music {
      background: url($assets-path + '/icons/icon-apple-music.svg') no-repeat center center / contain;
    }

    &--github {
      background: url($assets-path + '/icons/icon-github.svg') no-repeat center center / contain;
    }

    &--linkedin {
      background: url($assets-path + '/icons/icon-linkedin.svg') no-repeat center center / contain;
    }

    &--soundcloud {
      background: url($assets-path + '/icons/icon-soundcloud.svg') no-repeat center center / contain;
    }

    &--spotify {
      background: url($assets-path + '/icons/icon-spotify.svg') no-repeat center center / contain;
    }

    &--yunojuno {
      background: url($assets-path + '/icons/icon-yunojuno.svg') no-repeat center center / contain;
    }
  }
}
