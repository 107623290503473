@import '../../assets/scss/variables';
@import '../../assets/scss/mixins.scss';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.clients {
	margin-bottom: $spacer-3;

	@include media-breakpoint-up(md) {
		margin-bottom: $spacer-5;
	}

	&__title {
		margin-bottom: $spacer-4;
	}

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		list-style: none;
		padding: $spacer-2 0 0 0;

		li {
			@include fontSize(17px);
			align-items: center;
			border-left: 0;
			border-top: 0;
			display: flex;
			flex-basis: 50%;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0;
			padding: $spacer-1 0;
			position: relative;
			width: 50%;

			@include media-breakpoint-down(sm) {
				&:nth-child(4n+1), &:nth-child(4n+4) {
					background-color: rgba($color-dark-green-blue, 0.06);
				}
			}

			@include media-breakpoint-up(sm) {
				@include fontSize(20px);
			}

			@include media-breakpoint-up(md) {
				flex-basis: 33.33%;
				width: 33.33%;

				&:nth-child(even) {
					background-color: rgba($color-dark-green-blue, 0.06);
				}

			}

		}

	}

}
