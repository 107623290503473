@import './variables.scss';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.img-fluid {
  height: auto;
  max-width: 100%;
}

picture {
  display: block;
}

figure {
  margin-bottom: 0;

  @include media-breakpoint-down(xs) {
    margin-left: -($grid-gutter-width / 2);
    margin-right: -($grid-gutter-width / 2);
  }
}
