@import '../../assets/scss/_variables.scss';
@import '../../assets/scss/_mixins.scss';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.header--scrolled ~ .nav {
  @include media-breakpoint-down(xs) {
    top: $header-height-scrolled;
  }
}

.nav {
  @include component-transition;
  align-items: center;
  background-color: $color-black;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 999;
  -webkit-overflow-scrolling: touch;

  @include when-inside($nav-open-class) {
    opacity: 1;
    pointer-events: all;
    transition-timing-function: ease;
    visibility: visible;
  }

  @include media-breakpoint-down(xs) {
    margin-top: $scroll-progress-height;
    text-align: center;
    top: $header-height; // header height + scrolled header height
  }

  @include media-breakpoint-up(sm) {
    border: calculateRem(10px) solid rgba($color-dark, 0.5);
    flex-direction: row;
  }

  &__content {
    overflow-y: auto;
    overflow-x: hidden;
    padding: $spacer-10 0;
    width: 100%;
  }

  &__primary {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: ($spacer-1 * 1.5) 0;
    }

    li:first-child {
      margin-top: 0;
    }

    li:last-child {
      margin-bottom: 0;
    }

    li::after {
      background-color: $color-ugly-blue;
      content: '';
      display: block;
      height: calculateRem(1px);
      margin: $spacer-2 0;
      opacity: 0.5;
      width: calculateRem(20px);

      @include media-breakpoint-down(xs) {
        margin-left: auto;
        margin-right: auto;
      }

    }

    li:last-child::after {
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }

    .nav-item, button {
      @include fontSize(20px);
      line-height: 1.618;

      @include media-breakpoint-up(sm) {
        @include fontSize(24px);
      }
    }

    button {
      appearance: none;
      background: none;
      border: 0;
      color: $color-dusty-orange;
      padding: 0;
    }

    button:hover {
      color: $link-hover-color;
    }

  }

  &__secondary {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;

    @include media-breakpoint-down(xs) {
      margin-bottom: $spacer-3;

      a {
        @include fontSize(15px);
      }

    }

    li {
      line-height: $line-height-base;
    }

    li:not(:last-child) {
      @include media-breakpoint-up(sm) {
        margin-bottom: $spacer-1;
      }
    }

  }

  h4 {
    @include media-breakpoint-down(xs) {
      margin-bottom: $spacer-3;
      margin-top: $spacer-5;
    }
  }

  h5 {
    color: $color-ugly-blue;

    @include media-breakpoint-up(md) {
      @include fontSize(18px);
    }

  }

  .nav-item {
    border-bottom: 1px solid transparent;
    padding-bottom: calculateRem(2px);
  }

  .nav-item--active {
    border-color: $link-hover-color;
    color: $link-hover-color;
    //font-family: $font-family-bold;
  }

  .social {
    justify-content: center;
    margin-top: $spacer-3;

    @include media-breakpoint-up(sm) {
      bottom: $spacer-5;
      left: 50%;
      margin-top: 0;
      position: absolute;
      transform: translateX(-50%);
    }

  }

}
