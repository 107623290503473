@import '../../assets/scss/_variables.scss';

.footer {
	padding: $spacer-8 0;
	text-align: center;

	.social {
		justify-content: center;
		margin-bottom: $spacer-5;

		li.menu-item-hire {
			display: none;
		}
	}

	p {
		@include fontSize(14px);
		color: $color-ugly-blue;
		margin-bottom: $spacer-1;
	}
}
