@import '../../assets/scss/variables';
@import '../../assets/scss/mixins.scss';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.skills-tags {
	margin-bottom: $spacer-3;

	@include media-breakpoint-up(md) {
		margin-bottom: $spacer-5;
	}

	&__title {
		margin-bottom: $spacer-4;
	}

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			@include fontSize(12px);
			background: linear-gradient(to bottom, $color-black, $color-modal-bg);
			color: $color-light-peach;
			display: block;
			letter-spacing: calculateRem(1.5px);
			margin: 0 ($spacer-1 / 2) $spacer-1 ($spacer-1 / 2);
			padding: ($spacer-1 / 2) calculateRem(15px);
			text-transform: uppercase;

			@include media-breakpoint-up(sm) {
				@include fontSize(15px);
			}

		}

	}

}
