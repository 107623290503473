@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';
@import '../../../node_modules/bootstrap-scss/_functions.scss';
@import '../../../node_modules/bootstrap-scss/_variables.scss';
@import '../../../node_modules/bootstrap-scss/mixins/_breakpoints.scss';

.comp404 {
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100vh;
	justify-content: center;
	margin-top: -$main-p-top-xs;
	position: relative;

	@include media-breakpoint-up(sm) {
		margin-top: -$main-p-top-sm;
	}

	@include media-breakpoint-up(xl) {
		margin-top: -$main-p-top-xl;
	}

	&__video {
		bottom: 0;
		left: 0;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;

		&::after {
			background: $color-black;
			content: '';
			display: block;
			height: 100%;
			left: 0;
			opacity: 0.7;
			position: absolute;
			top: 0;
			width: 100%;
		}

		video {
			min-width: 1000%;
			min-height: 1000%;
			left: 50%;
			pointer-events: none;
			position: absolute;
			top: 50%;
			transform: translateX(-50%) translateY(-50%) scale(0.1);
		}

	}

	&__text {
		position: relative;
		text-align: center;
		width: 100%;

		h1 {
			margin-bottom: 0;
		}

		@include media-breakpoint-up(sm) {

			h1 {
				@include fontSize(60px);
			}

			p {
				@include fontSize(20px);
			}

		}

		@include media-breakpoint-up(md) {

			h1 {
				@include fontSize(80px);
			}

			p {
				@include fontSize(22px);
			}

		}

		@include media-breakpoint-up(lg) {

			h1 {
				@include fontSize(100px);
			}

		}

		@include media-breakpoint-up(xl) {

			h1 {
				@include fontSize(150px);
			}

			p {
				@include fontSize(24px);
			}

		}

	}

}
